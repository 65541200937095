.main-header {
    background: #FFFFFF;
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    z-index: 999;
}

.theme-btn {
    background: #00ADF0 !important;
    border-color: #00ADF0 !important;
    color: #fff;
    text-transform: capitalize;
    border-radius: 5px;
}

.theme-btn.lg {
    font-size: 1rem;
    padding: 0.75rem 1.625rem;
    min-width: 180px;
}

.main-header .navbar-expand-md .navbar-nav .nav-item {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-family: 'Inter', sans-serif;
}

.main-header .navbar-expand-md .navbar-nav .nav-link {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.12;
    color: #000000;
    font-family: 'Inter', sans-serif;
    padding: 0.625rem 0.938rem;
    text-transform: capitalize;
}

.container-fluid.c-padd {
    padding-left: 1rem;
    padding-right: 1rem;
}

.page-banner {
    background:url(../Images/landing/banner_shape.svg) no-repeat bottom left, linear-gradient(94.96deg, #005C97 0%, #3056D3 99.4%);
    padding: 6rem 1rem 2rem 1rem;
    background-size: 100%;
}


.page-banner-content h1 {
    font-weight: 400;
    font-size: 2.65rem;
    color: #FFFFFF;
    font-family: 'DM Serif Text', serif;
    line-height: 1.25;
}

.page-banner-content h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    margin: 20px 0 20px;

}

figure.imgbox {
    background: #FFFFFF;
    box-shadow: 0px 32px 64px -12px rgb(16 24 40 / 6%);
    border-radius: 10px;
    overflow: hidden;
    padding: 1.5rem 1.25rem;
    margin-top: 20px;
    margin-bottom: 0;
}

.section_padding {
    padding: 1.5rem 0;
}

.section_gradient {   
    background: url(../Images/landing/Bg_Shapes.png) no-repeat bottom right, linear-gradient(98.41deg, #48C6EF -0.68%, #6F86D6 100.23%) !important;
    background-size: 100%;   
  
}

.page-heading h2 {
    font-family: 'DM Serif Text', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1;
    letter-spacing: -0.02em;
    color: #2E2E2E;
}

.page-heading p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    color: #637381;
    font-family: 'Inter', sans-serif;
}

.page-heading {
    margin-bottom: 1.75rem;
}

.image_row {
    align-items: center;
}
.image_row .column-image {
    display: flex;
    overflow: hidden;
    justify-content: flex-end
}
.column_text h3 {
    font-family: 'DM Serif Text', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.25;
    color: #2E2E2E;
    margin-bottom: 1rem;
}
.column_text p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height:1.5;
    color: #637381;
    
}
.bullet_list li {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    color: #637381;
    padding: 10px 0px 10px 40px;
    position: relative;
    margin-left: 10px;
}
.bullet_list li::after {
    content: '';
    position: absolute;
    left: 0px;
    top: 9px;
    width: 28px;
    height: 28px;
    background: #F5F5F5;
    border-radius: 50%;
}
.bullet_list li::before {
    content: '';
    position: absolute;
    left: 9.5px;
    top: 19px;
    width: 9px;
    height: 9px;
    background: #637381;
    transform: rotate(-45deg);
    z-index: 1;
}
.section_gradient .column_text p,
.section_gradient .column_text h3,
.section_gradient .bullet_list li {
    color: #FFFFFF;
}
.imgbox-wrap {
    background: #FFFFFF;
    border: 1px solid #DADADA;
    box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.06);
    border-radius: 10px;
    padding: 1.325rem 1rem;
    overflow: hidden;
    margin-bottom: 0;
}

.about_swa small {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;
    text-transform: capitalize;
    color: #00AEF0;
    display: block;
    margin-bottom: 1rem;
}
.about_swa h2 {
    font-family: 'DM Serif Text', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.15;
    color: #2E2E2E;
    margin-bottom: 1.5rem;
}
.about_swa p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    color: #637381;
    margin-bottom: 1.25rem;
}
.btn.btn-black {
    background: #000000;
    border-radius: 5px;
    border-color: #000000;
    padding: 12px 15px;
    min-width: 180px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
}
.btn.btn-black:hover {
    color: #FFFFFF;
}

.demo_section {
    position: relative;
    padding: 2.5rem 0;
    background: linear-gradient(94.96deg, #005C97 0%, #23348A 99.4%);
}
.demo_section .container-fluid.c-padd {
    position: relative;
    z-index: 9;
    color: #fff;
    text-align: center;
}
.demo_section::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;   
    background: url(../Images/landing/pattern.svg) no-repeat left center;
    background-size: cover;
}
.demo_section h2 {
    font-family: 'DM Serif Text', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.25;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin: 0 auto 15px;
}
.demo_section h5 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
    color: #FFFFFF;
    margin: 0 auto 20px;
}

.support_form {
    background: #FFFFFF;
    box-shadow: 0px 4px 18px rgb(0 0 0 / 7%);
    border-radius: 8px;
    padding: 20px 20px;
    display: block;
    position: relative;
    margin-bottom: 25px;
}
.support_form::after {
    content: '';
    width: 106px;
    height: 133px;
    background: url(../Images/landing/Dotted.svg) no-repeat center top;
    background-size: cover;
    position: absolute;
    left: -21px;
    bottom: -23px;
    z-index: -1;
}

.section_connect h3 {
    font-family: 'DM Serif Text', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1;
    color: #2E2E2E;
    margin-bottom: .5rem;
}
.section_connect p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    color: #637381;
    max-width: 450px;    
}
.contact_info>small {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;
    text-transform: capitalize;
    color: #00AEF0;
    display: block;
    margin-bottom: 1rem;
}
.contact_ul {
    margin-top: 50px;
}
.contact_ul li {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
}

span.c_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(48, 86, 211, 0.05);
    border-radius: 5px;
    width: 40px;
    height: 40px;
    margin-right: 1rem;
}
.c_icon i {
    color: #637381;
    font-size: 1.25rem;
}
.c_info span {
    font-family: 'Inter', sans-serif;
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5;
    color: #2E2E2E;
}

.c_info small {
    font-family: 'Inter', sans-serif;
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5;
    color: #637381;
    max-width: 200px;
}

.small_footer {
    background: #222222;
}
.social_icon {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    color: #919699;
}
.footer-line {
    border-bottom: 1px solid #6C7073;
    padding-bottom: 10px;
}
.social_icon  a {  
    color: #919699;
    display: block;
    padding: 1px 12px;
    text-align: center;
    font-size: 18px;
    transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    text-decoration: none;
    overflow: hidden;
  }
  
  .social_icon  a:hover {
    transform: scale(1.2,1.2);
  }
    
  .social_icon  .facebook:hover {
    color: #3b5999;
  }
  
  .social_icon  .twitter:hover {
    color: #4A9CEC;
  }
  
  .social_icon  .instagram:hover {
    color: #D6274D;
  }
  
  .social_icon  .linkedin:hover {
    color: #1260A2;
  }
  
  .social_icon  .google:hover {
    color: #CE332A;
  }

.footer_copy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
}
.copy_link {
    display: flex;
    align-items: center;
}
.copy_link a {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6C7073;
    display: block;
    text-decoration: none;
    padding-right: 10px; 
    position: relative;  
}
.copy_link a::after {
    content: '.';
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #6C7073;
    position: absolute;
    right: 2px;
    top: 0px;
}
.copy_link a:last-child:after  {
    display: none;
}
.copy_link a:hover {
    color: #FFFFFF;
}
.copy_text span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6C7073;
    display: block;
}

.support_form .form-control {
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 5px;
}

@media screen and (min-width:992px) {
    .grid_50 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .page-banner-image {
        display: flex;
        justify-content: flex-end;
    }
    
    .main-header .navbar-expand-md .navbar-nav .nav-item .btn {
        margin-left: 1.563rem;
        font-weight: 600;
    }
        .page-banner {       
            padding: 9.5rem 0px 5.625rem;
        }

    .page-banner-content h1 {
        max-width: 48rem;
        letter-spacing: 0.5px;
        font-size: 3.75rem;
        line-height: 1;
    }
        .page-banner-content h5 {
            margin: 40px 0 50px;
        }
        figure.imgbox {  
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    .page-banner-image {
        padding-left: 3rem;
    }

    .page-heading h2 {        
        font-size: 3.75rem;       
    }

    .page-heading p {
        max-width: 768px;
        margin: 10px auto;
        font-size: 20px;
    }
    .image_row .column_image {
        padding-left: 2.125rem;
    }
    .image_row .column_text {
        padding-right: 4rem;
    }
    .column_text h3 {       
        font-size: 2.875rem;       
        margin-bottom: 2.5rem;
    }
    
    .column_text p {
        max-width: 560px;
        font-size: 18px;
        line-height: 28px;
    }
    .bullet_list li {      
        font-size: 18px;
        line-height: 1.5;
        padding: 10px 0px 10px 42px;
        margin-left: 22px;
    }
    .section_gradient .image_row .column_image {
        order: 1;
        padding-left: 0;
        padding-right: 2.125rem;
    }
    
    .section_gradient .image_row .column_text {
        order: 2;
        padding-right: 0;
        padding-left: 4rem;
    }
    .about_swa {
        padding-right: 3rem;
    }
    .about_swa h2 {        
        font-size: 46px;
        line-height: 54px;
        margin-bottom: 2.5rem;
    }
    .support_form {      
        max-width: 470px;
        padding: 50px 50px;       
        margin-left: auto;
    }
    .demo_section {       
        padding: 4.5rem 0;
    }
    .demo_section h2 {        
        font-size: 4rem;
        line-height: 1.25;       
        max-width: 760px;
        margin: 0 auto 25px;
    }
    .demo_section h5 {        
        max-width: 500px;
        margin: 0 auto 50px;
    }
    .section_connect h3 {        
        font-size: 2.875rem;
        margin-bottom: 1.75rem;
    }
    .support_form {        
        padding: 50px 50px;
        display: block;
        position: relative;
        margin-right: 50px;
        margin-top: 50px;
        margin-bottom: 25px;
    }
    .support_form::before {
        content: '';
        width: 106px;
        height: 133px;
        background: url(../Images/landing/Dotted.svg) no-repeat center top;
        background-size: cover;
        position: absolute;
        right: -40px;
        top: 68px;
        z-index: -1;
    }
    .support_form::after {
        content: '';
        width: 106px;
        height: 133px;
        background: url(../Images/landing/Dotted.svg) no-repeat center top;
        background-size: cover;
        position: absolute;
        left: -21px;
        bottom: -23px;
        z-index: -1;
    }
    .half_circle {
        position: absolute;
        width: 200px;
        height: 200px;
        display: block;
        border-radius: 50%;
        background-color: #01ACF0;
        border: none;
        z-index: -1;
        background-image: linear-gradient(180deg, transparent 50%, white 50%), linear-gradient(90deg, white 50%, transparent 50%);
        right: -50px;
        top: -50px;
        
    }
    span.c_icon {       
        width: 70px;
        height: 70px;
        margin-right: 24px;
    }
    .c_icon i {       
        font-size: 30px;
    }
    .c_info span {       
        font-size: 20px;
    }
       
}

@media screen and (min-width:1024px) {

    .page-banner-content h1 {
        font-size: 4.5rem;
    }

    .pl_64 {
        padding-left: 4rem !important;
    }

    .pr_50 {
        padding-right: 3.125rem !important;
    }

    .page-banner-image {
        padding-left: 4rem;
    }

    .page-banner-content {
        padding-right: 1.5rem;
    }

    .page-banner-content h5 {
        font-size: 1.25rem;
        max-width: 648px;
    }

    .section_padding {
        padding: 3.125rem 0;
    }

    .container-fluid.c-padd {
        padding-left: 3.125rem;
        padding-right: 3.125rem;
    }

    .page-heading {
        margin-bottom: 4.75rem;
    }
    
    .image_row .column_text {
        padding-right: 5.5rem;
    }
    .section_gradient .image_row .column_text {
        padding-right: 0;
        padding-left: 5.5rem;
    }
    .about_swa {
        padding-right: 4rem;
    }

}

@media screen and (min-width:1299px) {
    .page-banner-content h1 {
        font-size: 5.75rem;
    }

    .image_row .column_image {
        padding-left: 3.125rem;
    }
    .image_row .column_text {
        padding-right: 7.5rem;
    }
    .section_gradient .image_row .column_image {
        padding-left: 0;
        padding-right: 3.125rem;
    }
    
    .section_gradient .image_row .column_text {
        padding-right: 0;
        padding-left: 7.5rem;
    }
    .about_swa {
        padding-right: 7rem;
    }
}

@media screen and (min-width:1599px) {

    .inner-page-banner,
    .container-fluid.c-padd {
        max-width: 1600px;
        margin: 0 auto;
    }

    .container-fluid.c-padd {
        padding: 0 3rem;
    }

    .page-banner-image {
        padding-right: 3rem;
    }

    figure.imgbox {
        border-radius: 10px;
    }
}


@media screen and (max-width:991px) {
    .page-banner-content {
        text-align: center;
    }
    .column_image,
    .about_banner {
        margin-top: 25px;
    }
    .support_form {
        border: 1px solid #cdcdcd;
    }
    .footer_copy {
        flex-direction: column;
    }
    .copy_link {
        margin-bottom: 1rem;
    }
}