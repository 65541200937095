.admin-user-icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-bottom: 0px;
    display: flex;
    overflow: hidden;
    margin-right: 5px;
    align-items: center;
    justify-content: center;
    background-color: #34444C;
}
.admin-user-icon img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
}
.admin-user-icon span {
    font-size: 20px;
    color: #ffffff;
    display: block;
    font-weight: 600;
}
.user-menu {
    padding-right: 1rem;
}
.header.super-admin-header {
    background: #ffffff;
    box-shadow: none;
}
.header.super-admin-header .bar-icon span {
    background: #34444C;
}

.header.super-admin-header .user-menu.nav > li > a {
    color: #34444C; cursor: pointer;
}
.header.super-admin-header .user-menu.nav > li > a:hover, 
.header.super-admin-header .user-menu.nav > li > a:focus {
    background: transparent;
    /* transform: scale(1.1, 1.1); */
}
.ropdown-toggle a{ cursor: pointer !important; }
.dropdown-item{ cursor: pointer !important;}
.s-admin-name {
    color: #34444C;
    font-size: 16px;
    font-weight: 400;
}
.s-admin-name i {
    color: #34444C;
    font-size: 18px;
}
@media screen and (min-width: 992px) {
    .header.super-admin-header {
        left: 200px;
        transition: all 0.2s ease-in-out;
    }
    .mini-sidebar .header.super-admin-header {
        left: 60px;
        transition: all 0.2s ease-in-out;
    }
    .mini-sidebar.expand-menu .header.super-admin-header {
        left: 200px;
    }
    .header.super-admin-header .user-menu.nav > li > a > i {
        font-size: 28px;
    }
}
