* {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-transition: background-color 3s ease-out;
  -moz-transition: background-color 3s ease-out;
  -o-transition: background-color 3s ease-out;
  transition: background-color 3s ease-out;
}

.btn.btn-light-outlined {
  border: 1px solid #CACFD6;
  border-radius: 3px;
  color: #637381 !important;
  margin-right: 10px;
  background-color: #FFFFFF;
  padding: 0px 12px !important;
  height: 29px;
  line-height: 29px;
  font-size: 12px;
  font-weight: 500;
}


.btn.btn-light-outlined i{
  margin-right: 9px;
}

.btn.btn-light-outlined:hover {
  background-color: #34444C !important;
  border: 1px solid #34444C !important;
  color: #fff !important;
}
.btn.btn-light-outlined:hover i {
  color: #fff !important;
}

.abs-searchbox {
  position: relative;
  min-width: 175px;
}

.abs-searchbox i {
  position: absolute;
  color: #637381;
  left: auto;
  right: 10px;
  top: 10px;
  font-size: 15px;
}

.abs-searchbox .form-control {
  height: 32px;
  line-height: 32px;
  width: 185px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
  border: #CACFD6 1px solid;
  padding-right: 35px;

}

.company-header-wrap .c-icon {
  width: 30px;
  height: 32px;
  background-color: #4EC969;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.company-header-wrap .c-icon span.icon {
  font-size: 18px;
  color: #ffffff;
}

.company-header-wrap .c-icon.c {
  background: #828FE6;
}

.company-header-wrap .c-icon.s {
  background: #FF7E06;
}

.company-header-wrap .c-icon.i {
  background: #B671E1;
}

.company-header-wrap .c-icon.p {
  background: #71A4E1;
}

.company-header-wrap .c-icon.pl {
  background: #4EC969;
}


.company-header-wrap .c-icon i {
  font-size: 18px;
  color: #fff;
}

.company-header-wrap .pl-3 {
  padding-left: 10px !important;
}

.company-header-wrap small {
  display: block;
  color: #637381;
  text-transform: uppercase;
  font: 500 10px 'Inter', sans-serif;
  margin-bottom: 5px;
}

.company-header-wrap .super-page-title {
  margin-bottom: 0;
}

.super-page-title {
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.table-swagatham .ant-table-thead>tr>th {
  background-color: #f1f1f1 !important;
  color: #292929 !important;
  border-bottom: 0 !important;
  padding: 5px 10px;
  font: 600 14px 'Inter', sans-serif;
  color: #292929;
  white-space: nowrap;
}
.table-swagatham .ant-table-thead>tr>th:nth-child(2),
.table-swagatham .ant-table-tbody>tr>td:nth-child(2) {
  max-width: 80px;
  width: 79px;
}
.table-swagatham .ant-table-tbody>tr>td {
  padding: 4px 10px !important;
 
}

.table-swagatham .ant-table-tbody>tr>td a {
  text-decoration: none;
  color: #02ADEE;
  font-size: 12px;
}

.table-swagatham .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.table-swagatham .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
  border-right: 1px solid #E6E6E6 !important;
}
.ant-table-wrapper .ant-table-tbody >tr >th, 
.ant-table-wrapper .ant-table-tbody >tr >td {
  border-bottom: 1px solid #E6E6E6 !important;
  font-size: 12px; 
  color: #292929;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
.page-wrapper.supar-admin-page-wrapper .content {
  padding: 10px 10px;
  background: linear-gradient(180deg, #81BFE2 0%, rgba(116, 153, 174, 0) 100%);
  min-height: calc(100vh - 90px);
  padding-bottom: 120px;
}

.column-swa .col-form-label {
  font-size: 12px;
  color: #292929;
  font-weight: 500;
  padding-top: 0px;
}
.column-swa .col-form-label sup.text-danger {
  color: #FF0303 !important;
  top: 0;
}

.column-swa .form-control {
  height: 34px;
  background: #FFFFFF;
  border: 1px solid #CACFD6;
  border-radius: 6px;
  color: #292929;
  font-size: 12px;
}

.column-swa .form-control:focus,
.column-swa .form-control:hover {
  border-color: #292929;
}

.supar-admin-page-wrapper .card {
  margin-bottom: 10px;
  background: #FFFFFF;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  border-color:transparent ;
}
.table-filter {
  align-items: center;
  justify-content: space-between;
  display: flex;
}
.filter-arrow {
  opacity: 0;
  transition: all .2s ease-in-out;
}
.table-filter:hover .filter-arrow {
  opacity: 1;
}
.card-actions {
  display: flex;
  align-items: center;
}

.status-dropdown .btn.btn-light-outlined,
.status-dropdown select.form-control {
  min-width: 150px;
  background: url(../Images/angle-down.svg) no-repeat 91% center #fff;
  background-size: 10px;
  padding: 0px 12px ;
  height: 29px;
  line-height: 29px;
  font-size: 12px;
  font-weight: 500;
  padding-right: 45px !important;
  border-radius: 3px;
  color: #637381;  
}
.status-dropdown select.form-control.disabled {
  opacity: 0.65;
  pointer-events: none;
}

.status-dropdown select.form-control:hover {
  background-color: #34444C;   
    color: #fff;
}
.status-dropdown select.form-control:focus {
  background: url(../Images/angle-down.svg) no-repeat 91% center #fff;
  color: #637381;  
  border: 1px solid #CACFD6;
}
.status-dropdown select.form-control option {
  background: #ffff !important;
  color: #292929 !important;
}
.supar-admin-page-wrapper .card .card-body {
  padding: 10px 10px;
}

.ctab-links {
  display: flex;
  align-items: center;
}

.ctab-links a {
  font-weight: 500;
  font-size: 12px;
  padding: 0 15px;
  text-decoration: none;
  color: #637381;
  position: relative;
}

.ctab-links a.active {
  color: #02ADEE;
}

.ctab-links a::before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  height: 2px;
  width: 100%;
  background: transparent;
}

.ctab-links a.active::before {
  background: #02ADEE;
}

.page-wrapper.supar-admin-page-wrapper .card .card-body .table-responsive {
  width: calc(100% + 20px);
  margin-right: -10px;
  margin-left: -10px;  
}

.page-wrapper.supar-admin-page-wrapper .ant-table,
.page-wrapper.supar-admin-page-wrapper .ant-table-container,
.page-wrapper.supar-admin-page-wrapper .ant-table-container table,
.page-wrapper.supar-admin-page-wrapper .ant-table-container table tr th:first-child,
.page-wrapper.supar-admin-page-wrapper .ant-table-container table tr th:last-child {
  border-radius: 0px;
}
:where(.css-dev-only-do-not-override-ed5zg0).ant-checkbox-checked:after {
  border:1px solid #02ADEE !important;
}
:where(.css-dev-only-do-not-override-ed5zg0).ant-checkbox-checked .ant-checkbox-inner {
  background: #02ADEE !important;
  border-color: #02ADEE !important;
}
:where(.css-dev-only-do-not-override-ed5zg0).ant-checkbox .ant-checkbox-inner {
  background: #FFFFFF;
  border: 1px solid #CACFD6;
  border-radius: 2px;
}
:where(.css-dev-only-do-not-override-ed5zg0).ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background: #02ADEE ;
}

.page-wrapper.supar-admin-page-wrapper .card .card-title {
  font-size: 14px;
  color: #292929;
  font-weight: 700;
}



.ant-table-content tr:nth-child(even) {
  background-color: #F9F9F9;
}

.card-header {
  background: #ffffff;
  border-bottom: #CACFD6 1px solid;
  padding:10px 10px;
}

.ant-pagination li {
  border-radius: 0 !important;
}

.ant-pagination li.ant-pagination-item {
  border: #CACFD6 1px solid;
  font: 500 14px 'Inter', sans-serif;
  color: #637381 !important;
}

.ant-pagination li.ant-pagination-item:hover {
  border: #34444C 1px solid;
  background: #34444C !important;
  color: #ffffff !important;
}

.ant-pagination li.ant-pagination-total-text {
  font: 500 14px 'Inter', sans-serif;
  color: #292929 !important;
  border: none;
}

.ant-pagination li.ant-pagination-prev {
  color: #637381 !important;
  border: #CACFD6 1px solid;
  padding: 0 10px !important;
}

.ant-pagination li.ant-pagination-prev button {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ant-pagination li.ant-pagination-next {
  color: #637381 !important;
  border: #CACFD6 1px solid;
  padding: 0 0px !important;
}

.ant-pagination li.ant-pagination-next button {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ant-pagination li.ant-pagination-item.ant-pagination-item-active {
  border: #34444C 1px solid;
  background: #34444C !important;
  color: #ffffff !important;
}


.log-reg-part {
  display: flex;
  height: 100vh;
}

.log-reg-blank {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 600px);
  background: linear-gradient(136.62deg, #FFF1EB 0%, #ACE0F9 99.56%);
}

.log-reg-blank div {
  padding: 30px;
}

.log-reg-blank img {
  max-width: 100%;
}

.log-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  background: #FAFAFA;
}

.log-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -120px 0 25px 0;
}

.log-logo img {
  max-width: 100%;
}

.account-box .account-btn-new {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #34444C;
  border: 0;
  border-radius: 6px;
  color: #ffffff;
  height: 40px;
  padding: 0;
  font: 600 16px 'Inter', sans-serif;
}

.account-box .account-btn-new:hover {
  border: 0;
  opacity: 0.8;
}

.form-group select.form-control {
  background: url(../Images/angle-down.svg) no-repeat  97% center #fff;
  background-size: 10px;
  padding-right: 24px;
  
}
.form-group {
  position: relative;
}
.form-group .input-icon {
  position: absolute;
  right: 10px;
  top: 36px;
  cursor: pointer;
  z-index: 9;
}
.form-group .input-icon i {
  color: #637381;
  font-size: 16px;
}
.form-group .input-icon + .form-control {
  padding-right: 30px;
}
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.ReactModalPortal .ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.40) !important;
  z-index: 999;
}

.modal-main {
  background-color: #ffffff;
  border-radius: 12px;
  max-width: 350px;
}

.modal-main h3 {
  margin: 0 0 15px 0;
  padding: 0;
  font: 600 18px 'Inter', sans-serif;
  color: #292929;
  text-align: center;
}

.modal-main h2 {
  margin: 0 0 40px 0;
  padding: 0;
  font: 400 14px 'Inter', sans-serif;
  color: #637381;
  text-align: center;
  line-height: 20px;
}

.modal-main .button-set {
  display: flex;
  justify-content: space-between;
}

.modal-main .button-set .btn-primary {
  background-color: #34444C;
  font: 600 16px 'Inter', sans-serif;
  border: 1px solid #34444C;
  min-width: 170px;
  height: 44px;
}

.modal-main .button-set .btn-light-outlined {
  font: 600 16px 'Inter', sans-serif;
  min-width: 170px;
  height: 44px;
}

.card.footer-btn-card {
  position: fixed;
  width: calc(100% - 200px);
  bottom: 0;
  right: 0;
  left: 200px;
  margin-bottom: 0;
  border-radius: 0;
  z-index: 99;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  background-color: #ffffff;
}

.form-check-input[type=checkbox] {
  background: #FFFFFF;
  border: 1px solid #CACFD6;
  border-radius: 2px;
  box-shadow: none !important;
}
.form-check-input:checked {
  background: #02ADEE;
  border: 1px solid #02ADEE;
  border-radius: 2px;
}

.supar-admin-page-wrapper .card .form-check .form-check-input{
position: relative;
top: -3px;
margin-right: 10px;
width: 14px;
height: 14px;

}
.supar-admin-page-wrapper .card .form-check .form-check-label,
.supar-admin-page-wrapper .card .form-c-label{  
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;  
  color: #637381;
  margin-bottom: 0;
}
.supar-admin-page-wrapper .card .add-comp .btn.btn-primary {
  font-size: 12px;
}
div.status-dropdown:disabled {
  pointer-events: none;
}

.field-icon {
  position: absolute;
  right: 14px;
  bottom: 3px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #637381;
}

.btn:focus {
  box-shadow: none !important;  
  outline: none !important;
}

.btn:focus-visible {
  box-shadow: none !important;  
  outline: none !important;
}


.modal-icon {
  display: flex;
  justify-content: center;
  margin: 0 0 30px 0;
}

.modal-icon .modal-icon-tab {
  width: 58px;
  height: 58px;
  border-radius: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #fff 8px solid;
  font-size: 20px;
}

.modal-icon .modal-icon-tab.delete {
  color: #D92D20;
  background: #FEE4E2;
  border: 8px solid #fef3f2;
}


.column-swa .col-form-label.view-cel {
  display: flex;
}

.column-swa .col-form-label.view-cel span.view-label {
  color: #637381;
  font-weight: 400;
  padding-right: 10px;
  width: 180px;
  position: relative;
  font-size: 12px;

}

.column-swa .col-form-label.view-cel span.view-label::after {
  position: absolute;
  content:':';
  right: 0;
  font-size: 12px;
  color: #292929;
  font-weight: 600;
  margin-right: 5px;
}

.column-swa .col-form-label.view-cel span.view-value {
  font-weight: 400;
  color: #212B36;;
}

.column-swa .col-form-label .view-dec-label {
  display: block;
  color: #637381 !important;
  padding-bottom: 4px;
}

.column-swa .col-form-label .view-dec {
  font-weight: 500;
}

.row.sm-row {
  margin-left: -5px;
  margin-right: -5px;
}

.row.sm-row .col-12 {
  padding: 0 5px;
}

.bill-side>span,
.ship-side>span {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #637381;
  display: block;
  margin-bottom: 12px;
}
.card-action-wrap {
  padding: 0 10px;
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }

  30% {
    box-shadow: 9984px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}

@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }

  30% {
    box-shadow: 9999px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}

@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }

  30% {
    box-shadow: 10014px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

/**
 * ==============================================
 * Dot Pulse End
 * ==============================================
 */

 .domain-mask.input-icon {
  font-size: 12px;
  color: #292929;
  font-weight: 400;
}
.input-error {
  color: #FF0303;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 5px 0px;
}
.input-msg {
  color: #158b0a;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 5px 0px;
}
.input-error:empty,
.input-msg:empty {
  display: none;
}
.form-group.clickEventUrlCheck input.form-control {
  padding-right: 115px;
}

i.la.la-search {
  transform: rotate(268deg);
}

.ant-picker .ant-picker-input >input {
  font-size: 12px ;
  color: #292929;
  font-weight: 400;
}

.nodata-intable {
  height: 400px;
  max-height: 100vh;
  width: 100%;
  text-align: center;
}
.nodata-intable figure {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 7.813rem auto 1.75rem;
  background: rgba(227, 239, 250, 0.25);
  border-radius: 100%;
  overflow: hidden;
}
.nodata-intable figure img {
  object-fit: contain;
}

.nodata-intable h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  margin-bottom: 0px;
  color: #637381;
}



 @media screen and (min-width: 1280px) {
  .page-wrapper.supar-admin-page-wrapper {
    margin-left: 200px;
  }

  .mini-sidebar .page-wrapper.supar-admin-page-wrapper {
    margin-left: 60px;
  }

  .card-form-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 10px;
    margin-bottom: -10px;

  }
  .card-form-row.four-column {
    grid-template-columns: repeat(4, 1fr);
  }
  .card-form-row.two-column {
    grid-template-columns: repeat(2, 1fr);
  }
  .card-form-row.uneven-row {
    grid-template-columns: 1fr 1fr 3fr;
  }
  .column-swa .col-form-label.view-cel span.view-label {
   
    width: 185px;
    white-space: nowrap;
  
  }

}


@media screen and (max-width: 1279px) {

  .card-form-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .card-form-row.two-column {
    grid-template-columns: repeat(2, 1fr);
  }
  .log-reg-blank {
    width: calc(100% - 500px);
  }

  .log-tab {
    max-width: 500px;
  }

}

@media screen and (max-width: 1023px) {

  .card-form-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .log-reg-blank {
    display: none;
  }

  .log-tab {
    max-width: 100%;
  }

  .account-wrapper {
    padding: 20px;
  }

  .account-box {
    min-width: 100%;
  }

}

@media screen and (max-width: 479px) {

  .card-form-row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .log-logo {
    margin-top: -60px;
  }

}