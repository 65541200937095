.sidebar-logo {
    max-width: 100%;
    display: flex;
    width: 100%;
    height: 60px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    margin-bottom: 15px;
}

.sidebar-logo img {
    max-width: 100%;
    display: block;
    object-fit: contain;
    height: 100%;
}

.super-sidebar .sidebar-menu ul li a i {
    color: #f0f0f0;
    font-size: 18px;
    line-height: 1.5;
    width: 16px;
}

.super-sidebar .sidebar-menu ul li a {
    color: #f0f0f0;
    font-size: 14px;
    padding: 11px 17px;
}

.super-sidebar .sidebar-menu ul li a span {
    margin: 0;
}

.super-sidebar .sidebar-menu ul li a span.icon {
    margin: 0 12px 0 0;
    color: #ffffff !important;
    font-size: 14px;
}

.super-sidebar .sidebar-menu ul li ul li a:hover,
.super-sidebar .sidebar-menu ul li a:hover i {
    color: #ffffff;
}

.super-sidebar .sidebar-menu>ul>li>a:hover,
.super-sidebar .sidebar-menu>ul>li>a.active {
    color: #ffffff;
    background-color: #2C3B43;
    text-decoration: none;
}

.sidebar.super-sidebar {
    background-color: #34444C;
    border-right: 0;
}

.company-sidebar-link {

    color: white !important;
    padding: 10px;
    border-radius: 2px;
    text-decoration: none !important;
}

@media screen and (min-width: 992px) {
    .sidebar.super-sidebar {
        width: 200px;
        top: 0px;
    }

    .mini-sidebar .sidebar.super-sidebar {
        width: 60px;
    }

    .mini-sidebar.expand-menu .sidebar.super-sidebar {
        width: 200px;
    }
}